import styled, { css } from "styled-components";

import { IS_SMALL } from "~/utils/media";
import { Body } from "~/components/typography";
import { HeroTitleWrapper } from "~/components/hero/hero-title";

const CSS_CONFIG = css`
  --gutter: calc((100vw - min(60vw, 960px)) / 2);

  ${IS_SMALL} {
    --gutter: calc(100vw - 90vw) / 2;
  }
`;

export const MarketingBlock = styled.div`
  width: 60vw;
  max-width: 960px;
  margin: 0 auto 2rem;

  p + p {
    margin-top: 1rem;
  }

  &:last-of-type {
    margin-bottom: 8rem;
  }

  ${HeroTitleWrapper} + & {
    margin-top: 4rem;
  }

  ${IS_SMALL} {
    width: 90vw;
  }
`;

export const MarketingCopy = styled(Body)`
  margin-bottom: 2rem;
`;

export const MarketingBreak = styled.div`
  padding: 4rem 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accentTwo};

  a {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
    font-weight: 900;
  }
`;

export const MarketingImageFull = styled.figure.attrs(() => ({
  role: "image",
}))`
  ${CSS_CONFIG};
  margin: 0 auto 2rem;
  width: 100%;
  max-width: 960px;
  height: calc(min(100vw, 960px) * 0.67);
  background-image: url(${({ $imagePath }) => $imagePath});
  background-position: center top;
  background-size: cover;
`;
