import React from "react";

import { Main } from "~/layouts";
import { Seo } from "~/components/seo";
import { HeroTitle } from "~/components/hero";
import { MarketingBlock, MarketingCopy } from "~/components/marketing";
import { Footer } from "~/components/footer";

const MailWithLovePage = () => (
  <Main fullView>
    <Seo title="Mail With Love Charity" />
    <HeroTitle
      title={<>Mail With Love<br />Charity</>}
      align="center"
    />

    <MarketingBlock>
      <MarketingCopy as="div">
        <p>We know that a handwritten thought in a card can bring a smile. A well-crafted sentiment in a lovely card can provide encouragement and lift the spirits of the the recipient. Recently, we asked stationery designers to help us to spread  a little love. <i>Mail With Love</i> is a project that puts cards in the hands of people who need them.</p>
        <p><i>Mail With Love</i> is thrilled to have donated over 14,000 greeting cards to Ronald McDonald House, United Way, Joe DiMaggio Children’s Hospital, and Jack Niklaus Children's Hospital. After discussing the idea with various hospitals, we knew this was a great way to spread love. With patients and their families in the hospital over the holidays or during special occasions, cards can provide a much needed boost.</p>
        <p> If you are a greeting card designer or a snail mailer interested in donating, please reach out to <a href="mailto:hello@thegoodsnail.com">hello@thegoodsnail.com</a></p>
      </MarketingCopy>
    </MarketingBlock>
    <Footer />
  </Main>
);

export default MailWithLovePage;
