import styled, { css } from "styled-components";

const styles = [
  css`
    transform: translate(-100%, 0) rotateZ(-25deg);
    opacity: 0;
  `,
  css`
    z-index: 2;
    transform: translate(0, 0) rotateZ(-5deg);
    opacity: 1;
  `,
  css`
    z-index: 1;
    transform: translate(70%, 25%) rotateZ(5deg);
    opacity: 1;
  `,
  css`
    transform: translate(100%, 0) rotateZ(25deg);
    opacity: 0;
  `,
]

export const CardFlourish = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: block;
  width: 90%;
  max-width: 540px;
  height: 90%;
  max-height: 540px;
  background-color: ${({ theme }) =>
    theme.colors.accentOne};
`;

export const CardCarousel = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

export const CardContainer = styled.div`
  position: relative;
  top: 2rem;
  left: 4rem;
  display: block;
  width: 100%;
  height: 660px;
`;

export const CardAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  transition:
    transform 0.6s ease-out 0s,
    opacity 0.25s linear 0s;

  ${({ index }) => styles[index]};
`;

export const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  padding-right: 10%;
  text-align: right;
`;

export const HeroHeadline = styled.h1`
  ${({ theme }) => theme.typography.heroHeadline};
  color: ${({ theme }) => theme.colors.accentTwo};
`;

export const HeroSubheadline = styled.h2`
  ${({ theme }) => theme.typography.heroBody};
  color: ${({ theme }) => theme.colors.primary};
`;
