import React from "react";

import { Grid } from "~/components/grid";
import { HeroSubheadline } from "~/components/typography";

import {
  HeroSearchWrapper,
} from "./hero-search.style";

export const HeroSearch = ({ searchTerm }) => {
  return (
    <HeroSearchWrapper>
      <Grid>
        {searchTerm && (
          <HeroSubheadline>{searchTerm}</HeroSubheadline>
        )}
      </Grid>
    </HeroSearchWrapper>
  )
}
