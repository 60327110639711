import styled from "styled-components";

import { getImageSrc } from "~/utils/cloudinary";
import { IS_MEDIUM, IS_SMALL } from "~/utils/media";
import { HeroBody } from "~/components/typography";

export const HeroWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem;
  padding-top: 50%;
  min-height: 460px;
  max-height: 70vh;

  ${IS_SMALL} {
    flex-direction: column-reverse;
    padding-top: 0;
    margin-bottom: 0;
    max-height: none;
  }
`;

export const HeroImage = styled.img.attrs(({ alt }) => ({
  alt,
  role: "image",
}))`
  display: block;
  flex-grow: 1;
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 70%;
  height: 100%;
  transform: translateY(-50%);
  object-fit: cover;

  ${IS_SMALL} {
    position: relative;
    width: 100%;
    height: 40vh;
    min-height: 360px;
    object-position: center top;
    transform: none;
  }
`;

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 5%;
  text-align: right;
  transform: translateY(-50%);

  ${IS_MEDIUM} {
    right: 0;
  }

  ${IS_SMALL} {
    position: relative;
    margin: 0 auto 2rem;
    text-align: center;
    transform: none;

    button,
    ${HeroBody} {
      margin-right: auto;
      margin-left: auto;
    }
  }
`;

export const HeroHeadline = styled.h1`
  ${({ theme }) => theme.typography.heroHeadline};
  color: ${({ theme }) => theme.colors.accentTwo};
`;

export const HeroSubheadline = styled.h2`
  ${({ theme }) => theme.typography.heroBody};
  color: ${({ theme }) => theme.colors.primary};
`;

export const HeroFlourish = styled.div`
  position: absolute;
  left: -3rem;
  bottom: -3rem;
  display: block;
  width: 260px;
  height: 260px;
  background-image: url(${getImageSrc("elements/TGS-stamp-border-purple_aldsle")});
  background-repeat: no-repeat;
  background-size: contain;

  ${IS_SMALL} {
    display: none;
  }
`;
