import styled from "styled-components";

import { HeroSubheadline } from "~/components/typography";

export const HeroSearchWrapper = styled.div`
  padding: 4rem 0 3rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) =>
    theme.colors.accentFour};

  ${HeroSubheadline} {
    &:before {
      display: none;
    }
  }
`;
