import React from "react";
import { navigate } from "gatsby";
import Button from '@mui/material/Button';

import { getImageSrc } from "~/utils/cloudinary";
import { Grid } from "~/components/grid";
import {
  HeroHeadline,
  HeroBody,
} from "~/components/typography";

import {
  HeroWrapper,
  HeroImage,
  HeroContent,
  HeroFlourish,
} from "./hero-static.style";

export const HeroStatic = () => {
  // const src = "thegoodsnail11_lqmnqg.jpg";
  const src = "v-day-hero_jcksy2";
  const imagePath = getImageSrc(src, "w_600,dpr_2");

  return (
    <Grid>
      <HeroWrapper>
        <HeroImage
          src={imagePath}
          alt="Woman wearing a ring pop holding a card that says 'That rock though'."
        />
        <HeroFlourish />
        <HeroContent>
          <HeroHeadline color="accentTwo">
            From<br />URL to IRL
          </HeroHeadline>
          <HeroBody as="h2" color="primary">
            Never let your feelings<br />go undelivered.
          </HeroBody>
          <Button
            variant="contained"
            sx={{ marginTop: "1.5rem", minWidth: 200 }}
            onClick={() => navigate("/search")}
          >
            Shop Cards
          </Button>
        </HeroContent>
      </HeroWrapper>
    </Grid>
  );
}
